import React, { useState } from 'react'
import { getDatabase, ref,set,remove} from "firebase/database";

export default function Cooopons(props) {
    const [couponDetails,setCouponDetails]=useState({
        couponName:"",
        discountPercentage:"",
    })
    function Add(){
        const db = getDatabase();
        const x=Number(props.data.length)+1;
        set(ref(db, 'coupons/' + x), {
            couponName:couponDetails.couponName,
            discountPercentage:couponDetails.discountPercentage,
          });
    }
    function Onchange(event){
        const {name,value}=event.target;
        setCouponDetails(prevValue=>{
            return {
            ...prevValue,
            [name]:value
    }})

    }
    return (
        <div style={{"marginTop":"7%",marginLeft:"20%"}}>
            <div>
                <input type='text' name='couponName' value={couponDetails.couponName} onChange={Onchange} placeholder='Coupon Name' style={{margin:"1% 1% 1% 1%",width:"35%",height:"30px"}}></input>
                <input type='text' name='discountPercentage' value={couponDetails.discountPercentage} onChange={Onchange} placeholder='Discount Percentage' style={{margin:"1% 1% 1% 1%",width:"35%",height:"30px"}}></input>
                <button className="btn btn-success" style={{width:"15%",height:"30px",marginBottom:"1%"}} onClick={Add}>Add Coupon</button>
            </div>
            <table style={{ width: "97%"}} >
                <tr>
                    <th>Coupon</th>
                    <th >Discount %</th>
                    <th >Remove</th>
                </tr>
                {props.data.map((data, index) => {
                    return (
                        (
                            // (data.driverId.toLowerCase().includes(props.searchValue.toLocaleLowerCase())
                            //     &&
                            //     data.status.toLowerCase().includes(props.orderType))
                            // &&
                            <tr>
                                <td >{data.couponName}</td>
                                <td >{data.discountPercentage}</td>
                                <td style={{color:"red",cursor:"pointer"}}
                                onClick={()=>{
                                    const db = getDatabase();
                                    remove(ref(db, 'coupons/' + index));
                                }}>Remove</td>
                            </tr>
                        )
                    )

                })}
            </table></div>
    )
}
