import Home from './Screens/Home';
import Users from "../src/Screens/Users"
import Drivers from "../src/Screens/Drivers"
import NewOrders from './Screens/NewOrders';
import Login from './Screens/Login';
import VerifyDriver from "../src/Screens/VerifyDriver"
import Courier from "../src/Screens/CourierReq"
import Coupons from './Screens/Coupons'
import { initializeApp } from "firebase/app";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyCKujNyH_3NCW6pWuAFh9QGfkMEW4K7DIo",
    authDomain: "moveazy-c236d.firebaseapp.com",
    databaseURL: "https://moveazy-c236d-default-rtdb.firebaseio.com",
    projectId: "moveazy-c236d",
    storageBucket: "moveazy-c236d.appspot.com",
    messagingSenderId: "485560951691",
    appId: "1:485560951691:web:ee43c58dc9513bfcdac58e",

  };
  const app = initializeApp(firebaseConfig);


  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home app={app} />} />
        <Route path="/users" element={<Users app={app} />} />
        <Route path="/drivers" element={<Drivers app={app} />} />
        <Route path="/new-orders" element={<NewOrders app={app} />} />
        <Route path="/login" element={<Login app={app} />} />
        <Route path="/verify-driver" element={<VerifyDriver app={app} />} />
        <Route path="/courier-req" element={<Courier app={app} />} />
        <Route path="/coupons" element={<Coupons app={app} />} />
      </Routes>
    </Router>
  );
}

export default App;
